
<template>
  <div
    v-if="model"
    class="item relative p-16 sm:p-24 rounded-md h-full"
    :style="`background-color: ${backgroundColor};`"
  >
    <div
      v-if="model.icon"
      class="icon w-20 h-20 sm:w-24 sm:h-24 block absolute right-16 top-16 sm:right-24 sm:top-24"
    >
      <DynamicIcon
        :icon-name="model.icon"
      />
    </div>
    <WysiwygWrapper
      v-if="model.mainBody"
      v-epi-edit="'MainBody'"
      :style-config="{ 'wysiwyg wysiwyg--sm wysiwyg--sm-text': true }"
      :html="model.mainBody"
    />
    <div
      v-if="model.links && model.links.length > 0"
      :class=" {
        'mt-12': model.mainBody
      }"
    >
      <nuxt-link
        v-for="(link, index) in model.links"
        :key="index"
        :to="link.href"
        class="arrow-link block mb-12 text-xsmall sm:text-small-regular last:mb-0 flex items-center"
        :class="{
          'text-xsmall-medium sm:text-small-medium !no-underline sm:!leading-[22px]' : fullPath === link.href,
          'text-xsmall sm:text-small-regular' : fullPath !== link.href,
        }"
      >
        {{ link.text }}
      </nuxt-link>
    </div>
    <a
      v-if="model.icon === 'chat'"
      class="btn btn--secondary mt-16"
      href="javascript:$zopim.livechat.window.toggle()"
    >
      <i class="bg-success h-6 w-6 rounded-full mr-10 mb-2 align-middle inline-block" />
      {{ sharedResources.openChat }}
    </a>
  </div>
</template>

<script setup lang="ts">
import type { CustomerServiceCardItem } from '~/content-types';
import DynamicIcon from '~/components/DynamicIcon.vue';
import { useGlobalContentStore } from '~/store/globalContent';
const globalContentStore = useGlobalContentStore();
const sharedResources = globalContentStore.sharedResources;

defineProps<{
  model?: CustomerServiceCardItem,
  backgroundColor?: string,
}>();

const fullPath = ref('');
const route = useRoute();
fullPath.value = route.fullPath;
</script>
